import React, { useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';

import config from "../../config";
import Footer from "../section/footer";
import MFAOption from "./mfa"
import LoginControl from "../../controls/login";
import logoCircle from "../../images/iglu/092022/igluLogo_Secondary_RGB.svg";
import RegisterForm from "../section/register";
import ForgotPassword from "../forgot-password/index";

const  LoginPage = (props) =>{
  const [register, setRegister] = useState(false);
  const [fullHeight, setFullHeight] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [mfa, setMfa] = useState(null);

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  }

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  }

  return (

    <Container fluid className={`fh no-gutters login-main-body login-page ${fullHeight ? "vh-page" : ""} `}>
      <Container>
        <header className="login-main-logo">
          <a href="https://iglu.com.au" target="_blank"><img src={logoCircle} className="img-fluid logo-circle"/></a>
        </header>
      </Container>
      <Row className="fh-element align-items-center">
        <Col>
          {showForgotPassword && <ForgotPassword onClose={onCloseForgotPassword} />}
          {register &&
            <Container className="mb-5">
              <Container>
                <RegisterForm setFullHeight={async (data) => setFullHeight(data)} backToLogin={async () => setRegister(false)} />
              </Container>
            </Container>
          }
          {!register && <Container>
            <Row>
              <Col lg={6} className="mb-5 mb-lg-0 d-none d-lg-block">
                <Container>
                  <Row>
                    <Col>
                      <div className="banner-large-text d-none d-md-block mb-5">
                        <div>
                          Student
                          </div>
                        <div>
                          Accommodation
                          </div>
                        <div>
                          Wi-Fi Service
                          </div>
                      </div>
                      <div className="banner-small-text d-block d-md-none mb-3">
                        Student Accommodation Wi-Fi Service
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="banner-desc">
                        <div className="desc-title mb-2">
                          {"Warm Hospitality"}
                        </div>
                        <div className="desc-subtitle">
                          {"Cool design is just the beginning. We want you to always feel safe at home. That's why, in addition to offering 24x7 security, our friendly team and Resident Leaders - appointed onsite students providing after-hours support - are always on hand for you making your well-being and safety a priority."}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Modal
                show={mfa}
                className="login-mfa-panel-modal"
                onHide={()=> {}}
              >
                <MFAOption mfa={mfa} setMfa={setMfa} authUrl={config.auth} portalUrl={config.portal} apiUrl={config.backend}/>
              </Modal>
              {!mfa && ( <Col lg={6} className="mb-5 mb-lg-0">
                <Container>
                  <div className="login-container">
                    <div className="title mb-2">
                      {"Login"}
                    </div>
                    <LoginControl setMfa={setMfa} authUrl={config.auth} portalUrl={config.portal} apiUrl={config.backend}>
                      <Col xs={12} sm={6} className="vw-loginbtn">
                        <a className="forgot-password" onClick={handleClickForgotPassword}>{"Forgot Password?"}</a>
                      </Col>
                    </LoginControl>
                   
                  </div>
                </Container>
              </Col>)}
            </Row>
          </Container>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Footer />
        </Col>
      </Row>
    </Container>

  );
}


export default LoginPage;
